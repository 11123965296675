<template>
	<div class="p-seach">
		<div v-if="!classshow">
			<div class="p-s-c">
				共找到<span>{{data.total}}</span>门关于"<span>{{key}}</span>"的课程
			</div>
			<div class="p-s-h">
				首页<img src="../../../assets/img/home/searchleft.png">搜索结果
			</div>
		</div>
		<div class="p-s-a">
			<div class="p-s-a-l">
				<div class="mb20" v-if="classshow">
					<div class="p-s-type">
						<div class="h10"></div>
						<span>分类</span>
						<div v-for="(item,index) in itemclass[onechoose].children" :key="index" :class="['p-s-type-div', whatclass==item.id ? 'p-s-type-div p-s-type-div-choose':'']"
						 @click="whatclasschoose(item.id,index)">
							{{item.cat_name}}
						</div>
					</div>
					<div class="p-s-type-list">
						<div v-for="(item,indexa) in itemclass[onechoose].children[twochoose].children" :key="indexa" style="display: flex;flex-wrap: wrap;align-items: flex-start;">
							<div :class="['p-s-type-list-item', whatclass1==item.id ? 'p-s-type-list-item p-s-type-list-item-choose':'']"
							 @click="whatclasschoose1(item.id,indexa)"><span>{{item.cat_name}}</span></div>
							<div class="p-s-type-list-item-line"></div>
						</div>
					</div>
				</div>
				<div class="p-s-a-l-h">
					<div @click="whichchoose(1)" :class="['p-s-uchoose', choose==1 ? 'p-s-uchoose p-s-choose':'']">全部</div>
					<div @click="whichchoose(2)" :class="['p-s-uchoose', choose==2 ? 'p-s-uchoose p-s-choose':'']">免费</div>
					<div @click="whichchoose(3)" :class="['p-s-uchoose', choose==3 ? 'p-s-uchoose p-s-choose':'']">VIP会员</div>
					<div class='p-s-uchoose' style="display: flex;flex-wrap: wrap;align-items: flex-start;">
						<img @click="getgoods(0)" v-if="choosegood==1" src="../../../assets/img/home/choose.png">
						<img @click="getgoods(1)" v-if="choosegood==0" src="../../../assets/img/home/unchoose.png">
						好评
					</div>
					<div class='p-s-uchoose' style="display: flex;flex-wrap: wrap;align-items: flex-start;">
						<img @click="getsales(1)" v-if="choosesale==0" src="../../../assets/img/home/unchoose.png" />
						<img @click="getsales(0)" v-if="choosesale==1" src="../../../assets/img/home/choose.png" />
						畅销
					</div>
				</div>
				<div class="p-s-a-l-a">
					<div>
						<div :class="['c-s-a-item mr20', (index+1)%4==0 ? 'c-s-a-item ml20':'']" v-for="(item,index) in data.data" :key="index"
						 @click="todetail(item.id)">
							<div class="c-s-a-itemi-img"><img  class="c-s-a-itemi-img" :src="item.goods_img" /></div>
							<div class="c-s-item-title">{{item.goods_name}}</div>
							<div class="c-s-item-content">{{item.school_name}} | {{item.order_count}}人学过</div>
							<div style="display: flex;flex-wrap: wrap;align-items: flex-start;">
								<div class="c-span1" v-if="item.is_free!=1">￥169.90</div>
								<div class="c-span2"><span>vip免费</span></div>
							</div>
						</div>
					</div>
					<div class="s-el-pagination" v-if="total>12">
						<el-pagination @current-change="handleCurrentChange" background :current-page="currentPage" :page-size="12"
						 layout="prev, pager, next" :total="total"></el-pagination>
					</div>
				</div>

			</div>
			<div class="p-s-a-r">
				<div class="p-s-a-r-h">
					热门推荐
				</div>
				<div class="p-none">

				</div>
				<div @mouseenter="commonc=index" :class="['p-s-hot', commonc==index ? 'p-s-hot p-s-hot-c':'']" v-for="(item,index) in hot"
				 :key="index" @click="todetail(item.id)">
					<img class="p-s-hot-img" :src="item.goods_img" />
					<div class="p-s-hot-right">
						<div style="color: #333333;">{{item.goods_name}}</div>
						<div style="color: #F83850;">￥{{item.goods_price}}</div>
					</div>
					<div class="p-s-hot-line" v-if="index<(hot.length-1)"></div>
				</div>
				<div class="p-s-a-r-h">
					热门搜索
				</div>
				<div class="p-hot-s-s">
					<div v-for="(item,index) in keyword" :key="index" @click="choosehotwordw(index)" :class="['p-hot-word', choosehotword==index ? 'p-hot-word p-hot-word-c':'']"
					 @mouseenter="choosehotword=index">
						<span>{{item}}</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		metaInfo: {
			title: '逸教云课堂', // set a title
			meta: [{ // set meta
					name: '逸教云课堂',
					content: '新一代智能教育，让你的孩子与众不同'
				},
				{
					name: '智能教育',
					content: '想学习到逸教云课堂'
				}
			],
			link: [{ // set link
				rel: 'asstes',
				href: 'https://ykt.qdy2020.com/'
			}]
		},
		data() {
			return {
				// 分页
				list_rows: 12,
				page: 1,
				currentPage: 1,
				total: 0,
				//查询
				commonc: 0,
				classshow: "",
				choose: 1,
				choosehotword: 1,
				choosegood: 0,
				choosesale: 0,
				onechoose: 0,
				twochoose: 0,
				key: "",
				showchooseb: 1,
				threechoose: 0,
				whatclass: 0,
				whatclass1: 0,
				hot: [],
				data: [],
				keyword: [],
				itemclass: [{
						name: "办公软件",
						id: 1
					},
					{
						name: "效率工具",
						id: 2
					}
				],
			}
		},

		created() {
			this.gethotrecommon()
			this.gethotsearch()
			this.getSearch(this.$route.query.key, this.$route.query.id)
			this.key = this.$route.query.key
		},
		watch: {
			'$route': function() {
				this.getSearch(this.$route.query.key, this.$route.query.id)
			},
		},
		methods: {
			whichchoose(e) {
				this.choose = e
				this.getSearch(this.key, this.$route.query.id)
			},
			getgoods(e) {
				this.choosegood = e
				this.getSearch(this.key, this.$route.query.id)
			},
			getsales(e) {
				this.choosesale = e
				this.getSearch(this.key, this.$route.query.id)
			},
			goBack() {

			},
			choosehotwordw(e) {
				this.choosehotword = e
				this.getSearch(this.keyword[e])
			},
			//课程详情
			todetail(e) {
				this.$router.push({
					path: '/courseDetail',
					query: {
						id: e
					}
				})
			},
			gethotsearch() {
				this.$get("index/gethotkeyword", {}).then(response => {
					if (response.error == 0) {
						this.keyword = response.data.keywords
					} else {
						this.$message.error(response.msg)
						return
					}
				});
			},
			gethotrecommon() {
				this.$get("index/gethotgoods", {}).then(response => {
					if (response.error == 0) {
						this.hot = response.data.hotGoods
					} else {
						this.$message.error(response.msg)
						return
					}
				});
			},
			getsearh() {
				this.classshow = "1"
				this.$get("index/searchgoods", {
					list_rows: this.list_rows,
					cat_id: this.whatclass1,
					page: this.page,
					type: this.choose, //1-全部 2-免费 3-VIP
					is_hot: this.choosesale, //是否热销 1-是
					is_good: this.choosegood //是否好评 1-是
				}).then(response => {
					if (response.error == 0) {
						this.data = response.data.goodsList
						this.total = response.data.goodsList.total
						this.classshow = "1"
					} else {
						return
					}
				});
				return
			},
			getSearch(e, b) {
				this.key = e
				this.itemclass = this.$route.query.data
				if (this.$route.query.choose || this.$route.query.choose == 0) {
					this.onechoose = this.$route.query.choose
					this.whatclass = this.itemclass[this.$route.query.choose].children[0].id
					this.showchooseb = 2
					this.whatclass1 = this.itemclass[this.$route.query.choose].children[0].children[0].id
				}
				if (this.$route.query.twochoose || this.$route.query.twochoose == 0) {
					this.twochoose = this.$route.query.twochoose
					this.whatclass = this.itemclass[this.$route.query.choose].children[this.$route.query.twochoose].id
					this.showchooseb = 2
					this.whatclass1 = this.itemclass[this.$route.query.choose].children[this.$route.query.twochoose].children[0].id
				}
				if (this.$route.query.threechoose || this.$route.query.threechoose == 0) {
					this.whatclass = this.itemclass[this.$route.query.choose].children[this.$route.query.twochoose].id
					this.showchooseb = 2
					this.whatclass1 = this.itemclass[this.$route.query.choose].children[this.$route.query.twochoose].children[this.$route
						.query.threechoose].id
					this.twochoose = this.$route.query.twochoose
					this.threechoose = this.$route.query.threechoose
				}
				scrollTo(0, 0);
				if (b || b == 0) {
					this.classshow = "1"
					this.$get("index/searchgoods", {
						list_rows: this.list_rows,
						cat_id: this.whatclass1,
						page: this.page,
						type: this.choose, //1-全部 2-免费 3-VIP
						is_hot: this.choosesale, //是否热销 1-是
						is_good: this.choosegood //是否好评 1-是
					}).then(response => {
						if (response.error == 0) {
							this.data = response.data.goodsList
							this.total = response.data.goodsList.total
							this.classshow = "1"
						} else {
							return
						}
					});
					return
				}
				this.$get("index/searchgoods", {
					list_rows: this.list_rows,
					keyword: e,
					page: this.page,
					type: this.choose, //1-全部 2-免费 3-VIP
					is_hot: this.choosesale, //是否热销 1-是
					is_good: this.choosegood //是否好评 1-是
				}).then(response => {
					if (response.error == 0) {
						this.data = response.data.goodsList
						this.total = response.data.goodsList.total
						this.classshow = ""
					} else {
						return
					}
				});

			},
			whatclasschoose(e, b) {
				this.whatclass = e
				this.twochoose = b
				this.showchooseb = 2
				this.getsearh()
			},
			whatclasschoose1(e, b) {
				this.whatclass1 = e
				this.getsearh()
			},
			//当前页的变化
			handleCurrentChange(e) {
				this.page = e
				this.getSearch(this.key, this.$route.query.id)
			},
		}
	}
</script>


<style>
	.p-seach {
		position: relative;
		top: 30px;
		width: 76%;
		margin: 0 auto;
		background: #F6F6F6;
	}

	.p-s-c {
		width: 100%;
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #666666;
	}

	.p-s-c span {
		color: #00D0B0;
	}

	.p-s-h {
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		margin-top: 20px;
		color: #999999;
	}

	.p-s-h img {
		width: 7px;
		height: 12px;
		margin-top: auto;
		margin-bottom: auto;
		margin-left: 6px;
		margin-right: 6px;
	}

	.p-s-a {
		width: 100%;
		height: auto;
		margin-top: 21px;
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
		justify-content: space-between;
	}

	.p-s-a-l {
		width: 70%;
		height: auto;
	}

	.p-s-a-r {
		width: 25%;
		height: auto;
		margin-left: 30px;
	}

	.p-s-a-l-h {
		width: 100%;
		height: 54px;
		background: #FFFFFF;
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
	}

	.p-s-a-r-h {
		width: 100%;
		height: 54px;
		background: #efefef;
		line-height: 54px;
		font-size: 16px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #333333;
		text-align: center;
	}

	.p-s-a-l-a {
		width: 100%;
		height: auto;
		margin-top: 18px;
		display: flex;
		flex-direction: column;
	}

	.p-s-uchoose {
		width: 85px;
		height: 54px;
		background: #FFFFFF;
		line-height: 54px;
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		text-align: center;
		cursor: pointer;
		color: #333333;
	}

	.p-s-uchoose img {
		width: 18px;
		height: 18px;
		margin-top: auto;
		margin-bottom: auto;
		margin-right: 9px;
		margin-left: 9px;
	}

	.p-s-choose {
		background: #00D0B0 !important;
		color: #FFFFFF !important;
	}

	.p-none {
		height: 20px;
		width: 100%;
		background: #FFFFFF;
	}

	.p-s-hot {
		width: 100%;
		height: 100px;
		background: #FFFFFF;
		border-radius: 2px;
		display: flex;
		flex-wrap: wrap;
		cursor: pointer;
		align-items: flex-start;
	}

	.p-s-hot-line {
		height: 1px;
		width: 25%;
		background: #F4F4F4;
		margin-top: 100px;
		position: absolute;
	}

	.p-s-hot-c {
		background: #F9F9F9 !important;
	}

	.p-s-hot-img {
		width: 76px;
		height: 76px;
		border-radius: 5px;
		margin-top: auto;
		margin-bottom: auto;
		margin-left: 9px;
		margin-right: 11px;
	}

	.p-s-hot-right {
		width: 252px;
		font-size: 14px;
		margin-top: 11px;
	}
	.p-s-hot-right :nth-child(1){
		max-height: 62px;
		display: -webkit-box;
		-webkit-line-clamp: 3;
		overflow: hidden;
		text-overflow: ellipsis;
		-webkit-box-orient: vertical;
	}
	.p-hot-s-s {
		height: auto;
		width: 100%;
		background: #FFFFFF;
		margin-bottom: 20px;
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
	}

	.p-hot-word {
		margin-left: 12px;
		margin-right: 10px;
		text-align: center;
		line-height: 26px;
		height: 26px;
		border: 1px solid #E5E5E5;
		border-radius: 12px;
		margin-top: 10px;
		font-size: 14px;
		margin-bottom: 10px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #666666;
		cursor: pointer;
	}

	.p-hot-word-c {
		background: #00D0B0 !important;
		color: #FFFFFF;
	}

	.p-hot-word span {
		margin-left: 15px;
		margin-right: 15px;
	}

	/* 分类页面的内容 */
	.p-s-type {
		height: 35px;
		width: 100%;
		background: #FFFFFF;
		line-height: 35px;
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
	}

	.p-s-type span {
		margin-left: 10px;
		margin-right: 32px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #999999;
	}

	.p-s-type-div {
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #333333;
		margin-right: 39px;
		cursor: pointer;
	}

	.p-s-type-div-choose {
		color: #00D0B0;
	}

	.p-s-type-list {
		padding-top: 5px;
		height: 35px;
		width: 100%;
		background: #FFFFFF;
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
	}

	.p-s-type-list-item {
		margin-left: 16px;
		font-size: 12px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #666666;
		display: flex;
		text-align: center;
		flex-wrap: wrap;
		cursor: pointer;
		align-items: flex-start;
	}

	.p-s-type-list-item span {
		margin-left: 7px;
		margin-right: 7px;
	}

	.p-s-type-list-item-choose {
		color: #FFFFFF;
		background: #00D0B0;
		border-radius: 8px;
	}

	.p-s-type-list-item-line {
		width: 1px;
		height: 14px;
		background: #E6E6E6;
		margin-left: 16px;
		margin-top: auto;
		margin-bottom: auto;
	}

	.mb20 {
		margin-bottom: 20px;
	}

	.mr20 {
		margin-right: 44px !important;
		float: left !important;
	}

	.ml20 {
		float: right !important;
		margin-right: 0px !important;
	}

	.h10 {
		height: 5px;
		width: 100%;
	}

	.c-span2 span {
		padding-left: 3px;
		padding-right: 3px;
	}

	.s-el-pagination {
		height: 50px;
		width: 100%;
		text-align: center;
		margin-top: 10px;
	}
</style>
